<template>
  <b-card>
    <g-form @submit="save">
      <b-row class="p-0 mb-1">
        <b-col
          md="5"
        >
          <b-button
            :variant="file.name ? 'success' : 'adn'"
            data-action-type="new"
            class="btn-sm p-75"
            @click="
              (v) => {
                openModal();
              }
            "
          >
            {{ file.name ? $t("hasAttachMents") : $t("attachFile") }}
            <feather-icon
              icon="UploadIcon"
              class="ml-50"
            />
          </b-button>
          <a
            v-if="selectedItem.attachmentUrl"
            :href="selectedItem.attachmentUrl"
            class="ml-1 btn-sm btn btn-outline-adn p-75"
            @click.prevent="downloadItem(selectedItem)"
          >
            {{ $t("downloadAttachedFile") }}
            <feather-icon
              icon="DownloadIcon"
              class="ml-50"
            />
          </a>
        </b-col>
      </b-row>
      <hr class="border-bottom-primary border-darken-2">
      <b-modal
        ref="upload-modal"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="lg"
        hide-footer
        :title="$t('attachFile')"
      >
        <g-form>
          <!-- operations -->
          <b-row>
            <b-col cols="12">
              <div>
                <input
                  type="file"
                  class="excel-upload-input"
                  accept=".pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip, .xlsx, .PNG"
                  @change="handleFileUpload($event)"
                >
                <b-alert
                  v-if="file"
                  show
                  fade
                  class="mt-2 text-center"
                  variant="success"
                >
                  <div class="alert-body">
                    <span>{{ $t('importSuccsefly') }} {{ file.name }}</span>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              class="d-flex justify-content-end"
            >
              <b-button
                class="mr-1"
                variant="relief-primary"
                @click="closeModal"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                {{ $t("save") }}
              </b-button>
              <b-button
                variant="secondary"
                data-action-type="delete"
                :disabled="!file"
                @click="
                  () => {
                    removeAttachment();
                  }
                "
              >
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                {{ $t("cancel") }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-modal>

      <b-row class="center-element">
        <label
          v-if="helper.showAlert"
          class="main-font-size main-space mb-2 p-75"
          style="background-color: #9b6f16; color: #FFF; border-radius: 5px;"
        >
          <feather-icon
            icon="AlertTriangleIcon"
            class="mr-50 b-avatar-sm"
          />
          {{ $t('advancePaymentStartsFromNextMonthAsCurrentMonthSalaryIsCalculated') }} </label>
      </b-row>
      <b-row>
        <!-- code  -->
        <b-col
          v-if="selectedItem.id > 0"
          md="4"
        >
          <b-form-group>
            <g-field
              id="code"
              type="number"
              label-text="transactionNumber"
              name="code"
              readonly
              :value.sync="selectedItem.code"
            />
          </b-form-group>
        </b-col>

        <!-- transaction date  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value="getDate(selectedItem.transactionDate)"
              label-text="transactionDate"
              disabled
              name="transactionDate"
            />
          </b-form-group>
        </b-col>

        <!-- employee -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.employeeId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="employee"
              field="select"
              name="employee"
              rules="required"
              :options="employees"
              :label="isRight ? 'arabicName' : 'englishName'"
              :disabled="selectedItem.id > 0"
              @change="(v) => {
                selectedItem.departmentId = v.departmentId;
                isCurrentEmployeeAlreadyHavePayment(v.id);
              }"
            />
          </b-form-group>
        </b-col>

        <!-- department -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.departmentId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="department"
              field="select"
              name="department"
              :options="departments"
              disabled
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- value -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.total"
              label-text="advancePaymentValue"
              name="scalesCode"
              type="number"
              rules="required"
              @change="() => {
                calculate(selectedItem.installmentsNumber)
              }"
            />
          </b-form-group>
        </b-col>

        <!-- installmentsNumber -->
        <b-col md="4">
          <b-form-group>
            <g-field
              id="code"
              name="code"
              :value.sync="selectedItem.installmentsNumber"
              label-text="installmentsNumber"
              rules="required"
              type="number"
              @change="(installmentsNumber) => {
                calculate(installmentsNumber)
              }"
            />
          </b-form-group>
        </b-col>

        <!-- paymentAdvancePeriod  -->
        <b-col
          md="4"
          class="installments-methedolgy"
        >
          <label
            class="mb-50 main-font-size"
          > {{ $t('paymentAdvancePeriod') }} </label>
          <b-col
            md="12"
            class="center-element flex-column"
          >
            <div
              class="center-element"
            >
              <b-form-group
                style="width: 100px;"
              >
                <g-field
                  :value.sync="selectedItem.numberOfMonths"
                  type="number"
                  name="scalesCode"
                  readonly
                />
              </b-form-group>
              <label
                class="mr-2 ml-50 main-space"
                style="margin-top: -20px;"
              > {{ $t('month') }} </label>
              <b-form-group
                style="width: 100px;"
              >
                <g-field
                  :value.sync="selectedItem.numberOfYears"
                  name="scalesCode"
                  type="number"
                  readonly
                />
              </b-form-group>
              <label
                class="ml-50 main-space"
                style="margin-top: -20px;"
              > {{ $t('year') }} </label>
            </div>
            <label
              class="mb-2 main-space"
              style="margin-top: -20px; color: #283494;"
            > {{ `** ${$t('peroidEndDate')} : ${helper.advancePaymentPeroidEndDate}` }} </label>
          </b-col>
        </b-col>

        <!-- monthly Paid Value  -->
        <b-col
          md="4"
          class="d-flex flex-column"
        >
          <b-form-group>
            <g-field
              :value.sync="selectedItem.value"
              name="scalesCode"
              label-text="monthlyPaidValue"
              type="number"
              @change="(monthlyPaidValue) => {
                calculateReverse(monthlyPaidValue);
              }"
            />
          </b-form-group>

          <!-- expected value hint -->
          <label
            v-if="!helper.isValidRange || !helper.isValidMonthlyValue"
            class="mb-1 main-space"
            style="margin-top: -20px; color: red;"
          > {{ getHintMessage() }} </label>
        </b-col>

        <!-- notes -->
        <b-col md="12">
          <b-form-group>
            <label
              class="main-font-size"
            >
              {{ $t("notes") }}
            </label>
            <b-form-textarea
              id="textarea"
              v-model="selectedItem.notes"
              label="Notes"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- accept button -->
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            data-action-type="save"
            @click="save('saveOnly')"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            {{ this.$t('save') }}
          </b-button>

          <b-button
            class="mx-1"
            variant="outline-primary"
            data-action-type="save"
            @click="save('saveAndPrint')"
          >
            <feather-icon
              icon="PrinterIcon"
              class="mr-50"
            />
            {{ this.$t('saveAndPrint') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import saveAs from 'file-saver';

export default {
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      file: '',
      employees: [],
      departments: [],
      selectedItem: {
        employeeId: 0,
        departmentId: 0,
        transactionDate: '',
        value: 0,
        numberOfMonths: 0,
        numberOfYears: 0,
        installmentsNumber: 0,
        total: 0
      },
      helper: {
        advancePaymentPeroidEndDate: '',
        isValidMonthlyValue: true,
        isValidRange: true,
        expectedMonthlyValue: 0,
        isSalaryCalculated: false,
        showAlert: false
      }
    };
  },

  watch: {
    'selectedItem.employeeId'(val) {
      if (!val) {
        this.selectedItem.departmentId = null;
        this.helper.isSalaryCalculated = false;
      }
    }
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.transactionDate = this.today;
    if (this.id > 0) {
      this.getObject();
    }
    this.getEntites();
  },

  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    getObject() {
      this.get({ url: 'EmployeeBenefitsDeductions', id: this.id }).then((data) => {
        this.selectedItem = data;
        this.isCurrentEmployeeAlreadyHavePayment(data.employeeId);
      })
    },
    getEntites() {
      this.get({ url: 'employees' }).then((data) => {
        this.employees = data.filter(x => !x.isSuspended);
      })
      .then(() => {
        this.get({ url: 'departments' }).then((data) => {
          this.departments = data;
        })
      })
    },
    backToList() {
      this.$router.push({ name: 'employeeAdvancePayment' });
    },

    save(type) {
      // if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // is salary calculated
      if (this.selectedItem.isSalaryCalculated) {
        this.doneAlert({ text: this.$t('cantEditAsSalaryCalculated'), type: 'error', timer: 8000 });
        return;
      }

      // validate
      if (!this.isAllAsExpected()) return;
      this.prepareEntity();

      if (this.selectedItem.id > 0) {
        this.update({
          url: `EmployeeBenefitsDeductions/${true}`,
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          if (type === 'saveAndPrint') this.print(this.selectedItem.id);
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.backToList();
        });
      } else {
        this.create({
          url: `EmployeeBenefitsDeductions/${true}`,
          data: this.selectedItem
        }).then((dataId) => {
          if (type === 'saveAndPrint') this.print(dataId);
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.backToList();
        });
      }
    },

    isAllAsExpected() {
      if (!this.selectedItem.employeeId) return false;
      if (this.selectedItem.total <= 0) return false;
      if (this.selectedItem.installmentsNumber <= 0) return false;
      if (!this.helper.isValidMonthlyValue || !this.helper.isValidRange) return false;
      return true;
    },
    setMonthsAndYears() {
      this.selectedItem.numberOfMonths = this.selectedItem.installmentsNumber % 12;
      this.selectedItem.numberOfYears = (this.selectedItem.installmentsNumber - this.selectedItem.numberOfMonths) / 12;
    },
    getEndDate() {
      const endDate = new Date(this.selectedItem.transactionDate)
      endDate.setMonth(this.helper.isSalaryCalculated ? ((endDate.getMonth() + 1) % 12) + this.selectedItem.numberOfMonths : endDate.getMonth() + this.selectedItem.numberOfMonths);
      endDate.setFullYear(endDate.getFullYear() + this.selectedItem.numberOfYears);
      return endDate;
    },
    setAdvancePaymentPeroidEndDate() {
      this.setMonthsAndYears();
      const periodEndDate = this.getEndDate();
      this.helper.advancePaymentPeroidEndDate = this.getDate(periodEndDate);
    },
    setValidation(monthlyPaidValue) {
      this.helper.expectedMonthlyValue = (this.selectedItem.total / this.selectedItem.installmentsNumber).toFixed(3);
      this.helper.isValidMonthlyValue = parseFloat(this.helper.expectedMonthlyValue) === parseFloat(monthlyPaidValue);
      this.helper.isValidRange = parseFloat(monthlyPaidValue) > 0 && parseFloat(monthlyPaidValue) <= parseFloat(this.selectedItem.total);
    },
    getHintMessage() {
      return !this.helper.isValidRange
           ? this.$t('monthlyPaidValueMustBeGreaterThanZeroAndLowerThan', { limit: this.selectedItem.total })
           : `${this.$t('expectedMonthlyValue')} : ${this.helper.expectedMonthlyValue}`
    },
    calculate(installmentsNumber) {
      this.selectedItem.value = (this.selectedItem.total / installmentsNumber).toFixed(3);
      this.setAdvancePaymentPeroidEndDate();
      this.resetValidattion();
    },
    calculateReverse(monthlyPaidValue) {
      this.selectedItem.installmentsNumber = Math.round(this.selectedItem.total / monthlyPaidValue);
      this.setAdvancePaymentPeroidEndDate();
      this.setValidation(monthlyPaidValue);
    },
    resetValidattion() {
      this.helper.expectedMonthlyValue = 0;
      this.helper.isValidMonthlyValue = true;
      this.helper.isValidRange = true;
    },
    prepareEntity() {
      // get from date data
      const startDate = new Date(this.selectedItem.transactionDate)
      const currentMonth = startDate.getMonth() + 1;
      this.selectedItem.fromMonth = (this.helper.isSalaryCalculated ? (currentMonth % 12) + 1 : currentMonth).toString();
      this.selectedItem.fromYear = startDate.getFullYear().toString();

      // get to date data
      const endDate = this.getEndDate();
      this.selectedItem.toMonth = (endDate.getMonth() + 1).toString();
      this.selectedItem.toYear = endDate.getFullYear().toString();

      // set it as deduction and as advance Payment
      this.selectedItem.itemType = 2;
      this.selectedItem.isFixed = true;
      this.selectedItem.isAdvancePayment = true;
    },
    isCurrentEmployeeAlreadyHavePayment(employeeId) {
      const transdate = new Date(this.selectedItem.transactionDate)
      let month = transdate.getMonth() + 1;
      const year = transdate.getFullYear();
      this.get({ url: `EmployeeBenefitsDeductions/checkEmployeeSalaryCalculated?id=${employeeId}&month=${month}&year=${year}` }).then((isSalaryCalculated) => {
        this.helper.isSalaryCalculated = isSalaryCalculated;
        if (isSalaryCalculated) {
          month += 1;
        }
      }).then(() => {
        this.get({ url: `EmployeeBenefitsDeductions/checkEmployeeAdvancePayment?id=${employeeId}&month=${month}&year=${year}` }).then((alreadyHaveSolfa) => {
          if (!this.id && alreadyHaveSolfa) {
            this.doneAlert({ text: this.$t('employeeAlreadyHaveAdvancedPaymentTransaction'), type: 'error', timer: 8000 });
            this.selectedItem.employeeId = null;
            this.selectedItem.departmentId = null;
            this.helper.showAlert = false;
          } else {
          this.helper.showAlert = this.helper.isSalaryCalculated;
          }
        });

        if (this.id) {
          this.setAdvancePaymentPeroidEndDate();
        }
      });
    },
    print(advancePaymnetId) {
      const reportName = this.isRight ? 'AdvancedPayment-ar' : 'AdvancedPayment-en';
      const printedItem = {
        id: advancePaymnetId,
      };
      this.printReport(reportName, printedItem);
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.imageBase64Content = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t(
            'Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .xlsx, .PNG, .zip suffix files'
          ),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|xlsx|PNG|zip)$/.test(file.name);
    }
  },
};
</script>

<style>
.installments-methedolgy input {
  text-align: center;
}
</style>
